import React, { Component } from "react"
import "./style.scss"
import AccountBalance from "@components/_molecules/account_balance"
import FinishSetup from "@components/_molecules/finish_setup"
import AutoDeposit from "@components/_molecules/auto_deposit_status"
import ProfileMenu from "@components/_molecules/profile_menu"
import AppGreeting from "@components/_molecules/app_greeting"
import Courses from "@components/_molecules/learning_courses"
import * as data from "@data/pledge"
import BackPage from "@components/_molecules/back_parent_dashboard"
import OneTimeDepositStatus from "@components/_molecules/OneTimeDepositStatus"

class ParentProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      completeSetup: false,
      recurringDepositAmount: null,
      investmentAccountId: null,
      registrationCompletedAt: null,
      bankAccount: null,
      loading: true
    }
    this.handleStateChange = this.handleStateChange.bind(this)
  }

  handleStateChange = state => {
    this.setState({
      recurringDepositAmount: state.recurringDepositAmount,
      investmentAccountId: state.investmentAccountId,
      bankAccount: state.bankAccount,
      loading: false
    })

    // if (
    //   state.beneficiaryEquityValue == null ||
    //   state.recurringDepositAmount == null
    // )
    // NOTES: Emmanuel Kala <emmanuel(at)antmoney(dot)com>
    // Auto-deposits (recurring deposits) are currently disabled
    // Therefore, update the logic to simply check if the custodian
    // has any beneficiaries on record
    if (!state.hasBeneficiaries) {
      this.setState({
        completeSetup: true,
        registrationCompletedAt: state.registrationCompletedAt
      })
    }
  }

  render() {
    const { parentProfile } = data
    return (
      <div>
        <div className="heading-size">
          <BackPage data={parentProfile.heading} />
          <AppGreeting data={parentProfile.heading} />
        </div>
        <div className="parent-profile">
          <div className="rows">
            <div className="spacing">
              <FinishSetup
                data={{
                  completeSetup: this.state.completeSetup,
                  regCompletedAt: this.state.registrationCompletedAt,
                  bankAccount: this.state.bankAccount,
                  recurringDepositAmount: this.state.recurringDepositAmount
                }}
              />
              <AccountBalance handleStateChange={this.handleStateChange} />
              <Courses data={{ courseAmount: "14 courses this month" }} />
            </div>
            <div className="sizing">
              {/*
                NOTES: Emmanuel Kala <emmanuel(at)antmoney(dot)>
                Bank linking and auto-deposit are disabled
                so we'll simply hide this UI
              */}
{/*
              <AutoDeposit
                data={{
                  recurringDepositAmount: this.state.recurringDepositAmount,
                  bankAccount: this.state.bankAccount,
                  loading: this.state.loading
                }}
              />
              <OneTimeDepositStatus
                bankAccount={this.state.bankAccount}
                loading={this.state.loading}
              />
 */}
              <ProfileMenu
                data={{
                  investmentAccountId: this.state.investmentAccountId,
                  recurringDepositAmount: this.state.recurringDepositAmount,
                  bankAccount: this.state.bankAccount
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ParentProfile
