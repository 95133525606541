import React, { Component } from "react"
import { Link } from "gatsby"
import "./style.scss"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  accountState = (autoAmount, bankAccount) => {
    if (bankAccount) {
      return "/app/bank-settings"
    } else {
      return "/app/linked-account"
    }
  }
  render() {
    const { data } = this.props
    return (
      <div className="profile-menu container-app">
        <p className="title">ACCOUNT</p>
        {process.env.UPDATE_ACCOUNT_INFO_PAGE === "true" && (
          <div>
            <Link
              to="/app/account-info"
              state={{ investmentAccountId: data.investmentAccountId }}
            >
              Account Info
            </Link>
          </div>
        )}
        <div>
          <Link
            to={this.accountState(
              data.recurringDepositAmount,
              data.bankAccount
            )}
            state={{ investmentAccountId: data.investmentAccountId }}
          >
            Bank Settings
          </Link>
        </div>
        <div>
          <Link
            to="/app/statements"
            state={{ investmentAccountId: data.investmentAccountId }}
          >
            Your Documents & Statements
          </Link>
        </div>
        <div>
          <Link to="/app/change-password">Security</Link>
        </div>
      </div>
    )
  }
}
export default ProfileMenu
