import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import ParentProfile from "@components/parent_profile"

const ParentPage = () => {
  const { parentProfile } = data
  return (
    <Layout type="app">
      <SEO title={parentProfile.title} />
      <ParentProfile />
    </Layout>
  )
}

export default ParentPage
