import React, { Component } from "react"
import { Link } from "gatsby"
import "./style.scss"

class FinishSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      regPage: "",
    }
  }

  componentDidMount = () => {
    let { parentRegCompleted } = localStorage
    if (typeof parentRegCompleted === `undefined`) {
      localStorage.setItem("parentRegCompleted", "register")
      this.setState({ regPage: `your-info` })
    }
  }

  checkForAccount = (account, amount) => {
    if (account) {
      if (account === 4) {
        return <Link to={`/app/auto-deposit`}>Pending Bank Approval</Link>
      } else {
        // if (!amount) {
        //   return <Link to={`/app/auto-deposit`}>Set up an Auto-Deposit</Link>
        // } else {
        return <Link to="/app/linked-account">Link Your Bank</Link>
        // }
      }
    } else {
      return <Link to="/app/linked-account">Link Your Bank</Link>
    }
  }

  handleCopyState = (account) => {
    if (account === 4) {
      return (
        <p className="info-text">
          Account not complete. Your child can still learn even when its not
          funded.
        </p>
      )
    } else {
      return (
        <p className="info-text">
          Your child is not earning as much as they could be without a monthly
          auto-deposit
        </p>
      )
    }
  }

  render() {
    const { data } = this.props

    return (
      <div className={data.completeSetup ? "setup" : "hidden"}>
        <div className="title">
          {data.regCompletedAt ? (
            this.checkForAccount(data.bankAccount, data.recurringDepositAmount)
          ) : (
            <Link to={`/pledge/register/${this.state.regPage}`}>
              Finish Setup
            </Link>
          )}
        </div>
        {data.regCompletedAt ? (
          this.handleCopyState(data.bankAccount)
        ) : (
          <p className="info-text">
            Account not complete. Your child can still learn even when its not
            funded.
          </p>
        )}
      </div>
    )
  }
}

export default FinishSetup
